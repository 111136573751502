const HomeSEO = () => {
  return (
    <section className="block zero">
      <div className="fixed">
        <p>
          База недвижимости - удобный поиск и размещения объявлений от :
        </p>
        <ul>
          <li>собственников</li>
          <li>агентств недвижимости</li>
          <li>частных агентов</li>
          <li>застройщиков</li>
          <li>подрядчиков</li>
        </ul>
        <p>
          Вы получаете полноценный сервис по автоматической выгрузке ваших объявлений на сайты недвижимости, в автоматическом режиме
        </p>
        <p>
          Вы можете подключить ваш фид формата Яндекс, для загрузки на наш портал и автоматической выгрузки на другие сайты партнеры.
        </p>
        <p>
          Для вашего удобства мы можем реализовать практически любой нужный Вам сервис по вашему запросу.
        </p>
        <p>
          Официальный сайт объявлений по недвижимости База недвижимости ( База Эстейт) - инвестиции в недвижимость, актуальные объявления об аренде и продаже недвижимости.
        </p>
        <p>Доска объявление по недвижимости в режиме онлайн поможет подобрать недорогие варианты квартир, домов, земельных участков и пригороде, области.</p>
        <p>Вы можете подобрать любые интересующие вас объекты: вторичка, сталинки, гостинки, дома, дачи, коттеджи, таунхаусы, квартиры в новостройках и вторичное жильё. </p>
        <p>Коммерческую недвижимость: офисы, склады, торговые и свободные помещения.</p>

        <p>
          Квартиры и дома на одном сайте, онлайн-площадка для решения вопросов по инвестициям в недвижимость.
        </p>
        <p>
          Сотрудничество осуществляется со следующими финансовыми организациями:
        </p>
        <ul>
          <li>Абсолют Абсолютбанк</li>
          <li>Альфа Альфа-банк</li>
          <li>Газпромбанк</li>
          <li>Дом РФ</li>
          <li>Зенит</li>
          <li>Ипотека 24</li>
          <li>Кубань кредит банк</li>
          <li>Московский индустриальный банк</li>
          <li>Открытие</li>
          <li>Промсвязьбанк</li>
          <li>РНКБ</li>
          <li>РостФинанс</li>
          <li>Совкомбанк</li>
          <li>Уралсиб</li>
          <li>Совкомбанк</li>
          <li>Россельхозбанк</li>
          <li>Газпромбанк</li>
          <li>Росбанк</li>
          <li>Сбербанк</li>
          <li>ВТБ</li>
          <li>Транскапиталбанк</li>
          <li>Центр-инвест</li>
        </ul>
      </div>
    </section>
  );
};

export default HomeSEO;
